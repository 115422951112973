import localFont from 'next/font/local';

export const CalibreThin = localFont({
    src: [
        {
            path: '../../public/fonts/Calibrefonts/CalibreThin.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-calibre-thin',
});

export const CalibreLight = localFont({
    src: [
        {
            path: '../../public/fonts/Calibrefonts/CalibreLight.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-calibre-light',
});

export const CalibreRegular = localFont({
    src: [
        {
            path: '../../public/fonts/Calibrefonts/CalibreRegular.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-calibre-regular',
});

export const CalibreMedium = localFont({
    src: [
        {
            path: '../../public/fonts/Calibrefonts/CalibreMedium.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-calibre-medium',
});

export const CalibreSemibold = localFont({
    src: [
        {
            path: '../../public/fonts/Calibrefonts/CalibreSemibold.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-calibre-semibold',
});

export const CalibreBold = localFont({
    src: [
        {
            path: '../../public/fonts/Calibrefonts/CalibreBold.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-calibre-bold',
});

export const TiemposHeadlineBold = localFont({
    src: [
        {
            path: '../../public/fonts/Tiemfonts/TiemposHeadlineBold.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-tiempos-headline-bold',
});

export const TiemposHeadlineLight = localFont({
    src: [
        {
            path: '../../public/fonts/Tiemfonts/TiemposHeadlineLight.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-tiempos-headline-light',
});

export const TiemposHeadlineRegular = localFont({
    src: [
        {
            path: '../../public/fonts/Tiemfonts/TiemposHeadlineRegular.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-tiempos-headline-regular',
});

export const TiemposHeadlineMedium = localFont({
    src: [
        {
            path: '../../public/fonts/Tiemfonts/TiemposHeadlineMedium.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-tiempos-headline-medium',
});

export const TiemposHeadlineSemibold = localFont({
    src: [
        {
            path: '../../public/fonts/Tiemfonts/TiemposHeadlineSemibold.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-tiempos-headline-semibold',
});

export const CircularAirLight = localFont({
    src: [
        {
            path: '../../public/fonts/Tiemfonts/TiemposHeadlineLight.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-circular-air-light',
});

export const CircularAirBook = localFont({
    src: [
        {
            path: '../../public/fonts/Calibrefonts/CalibreRegular.woff2',
            style: 'normal',
        },
    ],
    variable: '--font-circular-air-book',
});

export const Tiempos = localFont({
    src: [
        {
            path: '../../public/fonts/Tiemfonts/TiemposHeadlineLight.woff2',
            weight: '300',
        },
        {
            path: '../../public/fonts/Tiemfonts/TiemposHeadlineRegular.woff2',
            weight: '400',
        },
        {
            path: '../../public/fonts/Tiemfonts/TiemposHeadlineMedium.woff2',
            weight: '500',
        },
    ],
    variable: '--font-tiempos',
});

export const TWKLausanne = localFont({
    src: [
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-100.woff2',
            weight: '100',
            style: 'normal',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-200.woff2',
            weight: '200',
            style: 'normal',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-300.woff2',
            weight: '300',
            style: 'normal',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-400.woff2',
            weight: '400',
            style: 'normal',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-500.woff2',
            weight: '500',
            style: 'normal',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-600.woff2',
            weight: '600',
            style: 'normal',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-700.woff2',
            weight: '700',
            style: 'normal',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-100Italic.woff2',
            weight: '100',
            style: 'italic',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-200Italic.woff2',
            weight: '200',
            style: 'italic',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-300Italic.woff2',
            weight: '300',
            style: 'italic',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-400Italic.woff2',
            weight: '400',
            style: 'italic',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-500Italic.woff2',
            weight: '500',
            style: 'italic',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-600Italic.woff2',
            weight: '600',
            style: 'italic',
        },
        {
            path: '../../public/fonts/TWKLausanne/TWKLausanne-700Italic.woff2',
            weight: '700',
            style: 'italic',
        },
    ],
    variable: '--font-twk-lausanne',
});

export const fontMapping = {
        CalibreThin,
        CalibreLight,
        CalibreRegular,
        CalibreMedium,
        CalibreSemibold,
        CalibreBold,
        TiemposHeadlineBold,
        TiemposHeadlineLight,
        TiemposHeadlineRegular,
        TiemposHeadlineMedium,
        TiemposHeadlineSemibold,
        CircularAirLight,
        CircularAirBook,
        Tiempos,
        TWKLausanne,
    },
    fontsClassNames = Object.keys(fontMapping).reduce(
        (acc, key) => `${acc} ${fontMapping[key].variable}`,
        '',
    );

export default fontsClassNames;
